import React from 'react';
import { ChevronRight, ChevronLeft, ArrowLeft, ArrowRight } from '../components/defaults/assets/svg/pagination';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { color } from '../components/defaults/styles/theme';
import mq from '../components/defaults/styles/mediaquerys';
import Link from './link';


const PaginationButton = ({className, icon, number, text, url}) => {

  
  return (
    <Link
     className={className}
     to={url}
      css={css`
        text-decoration: none;
        display: inline-flex;
        color: ${color.main_dark};
      `}
    >
      <PaginationContent icon={icon} number={number} text={text} active />
    </Link>
  )
}

const PaginationContent = ({className, icon, number, text, active}) => {
  return (
    <li
      className={className}
      css={css`
        border-radius: 3px;
        padding: 0.25em;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-items: center;
        svg {
          width: 1.5em;
          height: auto;
          padding: 0.25em;
          opacity: ${active ? 1 : 0.35};
        }
        p {
          font-size: 0.75em;
          font-weight: 500;
          margin: 0;
          padding: 0.25em;
          opacity: ${active ? 1 : 0.35};
        }
      `}
    >
      {icon && text === "First" ? (
        <>
          {icon}
          <p
            css={css`
              display: none;
              ${mq[1]} {
                display: inline;
              }
            `}
          >
            {text}
          </p>
        </>
      ) : icon && text === "Last" ? (
        <>
          <p
            css={css`
              display: none;
              ${mq[1]} {
                display: inline;
              }
            `}
          >
            {text}
          </p>
          {icon}
        </>
      ) : number ? (
        <>
          <p>{number}</p>
        </>
      ) : icon ? (
        <>{icon}</>
      ) : (
        ""
      )}
    </li>
  )
}


const PaginationFrame = styled.ul`
  list-style: none;
  margin: 0;
  line-height: 1em;
  display: inline-flex;
  & > * {
    &:not(:last-child) {
      margin-right: 0.5em;
    }
  }
  .nonmobile {
    display: none;
    ${mq[1]} {
      display: inline-flex;
    }
  }
`


const Pagination = ({ numPages, currentPage, basePage }) => {
  let pageArray = []
  const arrayLength = (numPages >= 5) ? 5 : numPages
  
  const calcPageArray = (i, currentPage) => {
    Array.from({ length: arrayLength }).forEach(() => {
      pageArray.push(currentPage + i)
      i = i + 1
    })
  }

  if (currentPage <= 3) {
    let i = -(currentPage)
    calcPageArray(i, currentPage + 1)
  } else if (numPages - currentPage === 1) {
    let i = -3
    calcPageArray(i, currentPage)
  } else if (numPages - currentPage === 0) {
    let i = -4
    calcPageArray(i, currentPage)
  } else {
    let i = -2
    calcPageArray(i, currentPage)
  }


  const prevPage = currentPage === 2 ? basePage : basePage + `/` + (currentPage - 1)
  const nextPage = basePage + `/` + (currentPage + 1)

  const targetPage = (item) => {
    if (item === 1) {
      return basePage
    } else {
      return basePage + `/` + (item)
    }
  }

  const nonMobile = (index) => {
    if( arrayLength === 5 ) {
      if ( (currentPage === 1 || currentPage === 2) && (index === 3 || index === 4)) {
        return "nonmobile"
      } else if ( ((numPages - currentPage === 1) || (numPages - currentPage === 0)) && (index === 0 || index === 1)) {
        return "nonmobile"
      } else if ( (currentPage > 2 && numPages - currentPage > 1) && (index === 0 || index === 4)) {
        return "nonmobile"
      } else {}
    }
  }

  // console.log(nonMobile(1) + ' ' + nonMobile(2) + ' ' + nonMobile(3) + ' ' + nonMobile(4) + ' ' + nonMobile(5))
  

  return (
    <>
      <div
        css={css`
          display: flex;
          justify-content: center;
          padding-bottom: 5em;
        `}
      >
        <PaginationFrame>
          {currentPage === 1 ? (
            <>
              <PaginationContent icon={<ArrowLeft />} text="First" />
              <PaginationContent icon={<ChevronLeft />} />
            </>
          ) : (
            <>
              <PaginationButton
                icon={<ArrowLeft />}
                text="First"
                url={basePage}
              />
              <PaginationButton icon={<ChevronLeft />} url={prevPage} />
            </>
          )}

          {pageArray.map((item, index) =>
            currentPage === item ? (
              <PaginationContent
                number={item}
                key={index}
                className={nonMobile(index)}
              />
            ) : (
              <PaginationButton
                number={item}
                key={index}
                url={targetPage(item)}
                className={nonMobile(index)}
              />
            )
          )}

          {currentPage === numPages ? (
            <>
              <PaginationContent icon={<ChevronRight />} />
              <PaginationContent icon={<ArrowRight />} text="Last" />
            </>
          ) : (
            <>
              <PaginationButton icon={<ChevronRight />} url={nextPage} />
              <PaginationButton
                icon={<ArrowRight />}
                text="Last"
                url={basePage + `/` + numPages}
              />
            </>
          )}
        </PaginationFrame>
      </div>
    </>
  )
}

export default Pagination;