const PartnerGroups = (el, outputStyle) => {

  const groups = [
    {
      name: "Founding Partner",
      levels: [
        "Founding"
      ]
    },
    {
      name: "Anchor Partner",
      levels: [
        "Anchor",
        "Founding Anchor"
      ]
    },
    {
      name: "Ecosystem Partner",
      levels: [
        "Ecosystem"
      ]
    }
  ]

  groups.forEach(group => {
    let tmpArray = []

    group.levels.forEach(level => {
      let matchingGroups = el.filter(item => item.fieldValue === level)
      matchingGroups.forEach(x => {
        x.edges.forEach(y => {

          if (y.node.data.start_date === null) {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();

            y.node.data.start_date = yyyy + "-" + mm + "-" + dd
          }
        
          tmpArray.push(y)
        })
      })
    })

    tmpArray.sort((a, b) => {
      if (a.node.data.start_date === b.node.data.start_date) {
        if (a.node.data.name > b.node.data.name) {
          return 1
        } else {
          return -1
        }
      } else {
        if (a.node.data.start_date > b.node.data.start_date) {
          return 1
        } else {
          return -1
        }
      }
    })
    
    group["companies"] = tmpArray

    
  })


  if (outputStyle === "items") {

    let itemArray = []
    groups.forEach(group => {
      group.companies.forEach(company => {
        itemArray.push(company)
      })
    })
    return itemArray;

  } else if (outputStyle === "graphQlGroup") {
    // meant to be used for the card_group elements which uses the GraphQl group output
    let graphQlGroup = []
    groups.forEach(group => {
      graphQlGroup.push({
        edges: group.companies,
        fieldValue: (group.name).replace("Partner", "")
      })
    })
    return graphQlGroup;

  } else {
    
    return groups;

  }



}

export default PartnerGroups;