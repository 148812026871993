import React from "react"
import "@fontsource/inter/latin.css"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { color } from "../components/defaults/styles/theme"
import { wrapper } from "../components/defaults/styles/elements"
import { css } from "@emotion/react"
import GeneralFooter from "../components/general_footer"
import {
  TitleHeader,
  PeopleDisclaimer,
} from "../components/defaults/styles/elements"
import PeopleIcon from "../components/defaults/assets/svg/users.svg"
import { ExpCardList } from "../components/main/expcard"
import SubNav from "../components/main/sub_nav"
import PeopleCard from "../components/main/expcard-people"
import { ExpCardListItem } from "../components/main/expcard"
import Pagination from "../components/pagination"
import PartnerGroups from "../components/defaults/filters/partnerGroups"

const seo = {
  frontmatter: {
    title: "People",
  },
}

const nav_data = [
  {
    name: "All People",
    url: "/people/all",
  },
  {
    name: "Speakers",
    url: "/people/speakers",
  },
  {
    name: "Corporate Champions",
    url: "/people/champions",
  },
  {
    name: "Startup Employees",
    url: "/people/startupemployees",
  },
  {
    name: "EXPO Management",
    url: "/people/ops",
  },
]

const PeopleCardGroup = ({ data, skip, limit }) => {
  // the idea is to first group by Partner, then sort according to belows partner list (PartnerGroups…)
  // and afterwards access the respective employees which have been sorted by graphql based on their surname

  let orderedPartners = []
  let orderedPeople = []
  let index = null

  // access the array which includes the groups
  data.champions.group.map(({ edges, fieldValue }) => {
    // get the position which the accessed group would have in the partnerOrder array
    //index = partnerOrder.indexOf(fieldValue)
    index = PartnerGroups(data.companies.group, "items").findIndex(
      x => x.node.data.name === fieldValue
    )

    if (index > orderedPartners.length) {
      // if the calculated position is beyond the new arrays length create as much empty items as possible to
      // position the company at the right spot
      let diff = index - orderedPartners.length
      Array.from({ length: diff }).forEach(() => {
        orderedPartners.push(undefined)
      })
      orderedPartners.push(edges)
    } else {
      // if not cut the respective position and place the company item there
      orderedPartners.splice(index, 1, edges)
    }

    return undefined
  })

  // remove all undefined elements in the array (as there might happen some arrows otherwise)
  Object.keys(orderedPartners).forEach(key =>
    orderedPartners[key] === undefined ? delete orderedPartners[key] : {}
  )

  orderedPartners.forEach(x => x.forEach(y => orderedPeople.push(y)))

  return orderedPeople
    .slice(skip, skip + limit)
    .map(({ node }, index) => <PeopleCard data={node} key={index} />)
}

const People = ({ data, pageContext }) => {
  const {
    numPages,
    currentPage,
    champions,
    basePage,
    dedicated_comp,
    id_based,
    skip,
    limit,
  } = pageContext

  return (
    <Layout seo={seo}>
      <TitleHeader
        title={
          dedicated_comp
            ? `Attendees of ` + data.company.data.name
            : `The People`
        }
        subtitle="<p>We have an expansive network of innovators all around the world that make up our community - founders, industry experts, entrepreneurs, investors, artists, doctors, students, and everything in between. Part of what we love so much about our events is having the opportunity to come together from all walks of life to exchange ideas on topics we are passionate about.</p>"
        icon={{
          svg: <PeopleIcon />,
          style: "fill",
        }}
      />

      <div css={wrapper}>
        <SubNav nav_data={nav_data} />
      </div>

      <section
        css={css`
          background: ${color.main_light};
          position: relative;
          margin-top: -2.5rem;
        `}
      >
        <ExpCardList>
          {champions ? (
            <PeopleCardGroup data={data} skip={skip} limit={limit} />
          ) : dedicated_comp || id_based ? (
            data.employees.edges !== undefined &&
            data.employees.edges.length !== 0 ? (
              data.employees.edges.map(({ node }, index) => (
                <PeopleCard data={node} key={index} />
              ))
            ) : (
              ""
            )
          ) : (
            data.allPeople.edges.map(({ node }, index) => (
              <PeopleCard data={node} key={index} />
            ))
          )}

          <ExpCardListItem placeholder_item></ExpCardListItem>
          <ExpCardListItem placeholder_item></ExpCardListItem>
          <ExpCardListItem placeholder_item></ExpCardListItem>
          <ExpCardListItem placeholder_item></ExpCardListItem>
          <ExpCardListItem placeholder_item></ExpCardListItem>
        </ExpCardList>

        <div css={wrapper}>
          <Pagination
            numPages={numPages}
            currentPage={currentPage}
            basePage={basePage}
          />
        </div>

        <div css={wrapper}>
          <PeopleDisclaimer
            css={css`
              text-align: center;
              margin: 0 auto;
              padding-left: 1em;
              padding-right: 1em;
            `}
          />
        </div>
      </section>
      <GeneralFooter theme={color.main_light} />
    </Layout>
  )
}

export const query = graphql`
  query People(
    $skip: Int!
    $limit: Int!
    $group: [String!]
    $sort: [AirtableFieldsEnum]
    $order: [SortOrderEnum]
    $champions: Boolean!
    $dedicated_comp: Boolean!
    $id_based: Boolean! = false
    $airtable_id: String
    $users: [String]
  ) {
    allPeople: allAirtable(
      filter: {
        table: { eq: "people" }
        data: {
          group: { in: $group }
          include: { eq: true }
          consent: { eq: true }
        }
      }
      sort: { fields: $sort, order: $order }
      limit: $limit
      skip: $skip
    ) @skip(if: $id_based) {
      edges @skip(if: $champions) {
        node {
          recordId
          id
          data {
            manual_sorting
            name
            image {
              localFiles {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
                }
              }
            }
            partner_company {
              data {
                name
              }
            }
            startup_company {
              data {
                startup
                password_protected
              }
            }
            custom_company
            profession
          }
        }
      }
    }
    champions: allAirtable(
      filter: {
        table: { eq: "people" }
        data: {
          group: { in: $group }
          include: { eq: true }
          consent: { eq: true }
        }
      }
      sort: { fields: $sort, order: $order }
    ) @include(if: $champions) {
      group(field: data___partner_company___data___name) {
        edges {
          node {
            id
            recordId
            data {
              name
              image {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
                  }
                }
              }
              partner_company {
                data {
                  name
                }
              }
              profession
            }
          }
        }
        fieldValue
      }
    }
    company: airtable(table: { eq: "partners" }, id: { eq: $airtable_id })
      @include(if: $dedicated_comp) {
      data {
        name
      }
    }
    companies: allAirtable(
      filter: { data: { active: { eq: true } }, table: { eq: "partners" } }
      sort: { fields: [data___start_date, data___name], order: ASC }
    ) @include(if: $champions) {
      group(field: data___type) {
        edges {
          node {
            data {
              name
              start_date
            }
            recordId
          }
        }
        fieldValue
      }
    }
    employees: allAirtable(
      filter: {
        table: { eq: "people" }
        id: { in: $users }
        data: { include: { eq: true }, consent: { eq: true } }
      }
      sort: { fields: $sort, order: $order }
      limit: $limit
      skip: $skip
    ) @include(if: $id_based) {
      edges {
        node {
          id
          recordId
          data {
            name
            profession
            partner_company {
              data {
                name
              }
            }
            startup_company {
              data {
                startup
                password_protected
              }
            }
            custom_company
            image {
              localFiles {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  }
`

export default People
